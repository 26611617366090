const ClientWoocommerce = (endpoint, search) => {
    const base_url = `${process.env.REACT_APP_WOO_BASE_URL}${endpoint}?${search}&${process.env.REACT_APP_WOO_CS}&${process.env.REACT_APP_WOO_CK}`;
    return base_url;
}

const ClientWoocommerceACF = (endpoint, per_page) => {
    const base_url = `${process.env.REACT_APP_ACF_BASE_URL}${endpoint}?per_page=${per_page}`;
    return base_url;
}

export {ClientWoocommerce, ClientWoocommerceACF};
