import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faArrowDown, faTrash } from '@fortawesome/free-solid-svg-icons';

function NextArrow(props) {
    const { className, onClick, size, color } = props;
  
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowRight') {
        onClick();
      }
    };
  
    return (
      <div
        className={className}
        onClick={onClick}
        tabIndex={0}
        role="button"
      >
        <FontAwesomeIcon icon={faChevronLeft} color={color} size="6x" style={{ height: size, transform: "rotate(180deg)" }} />
      </div>
    );
  }
  function PrevArrow(props) {
    const { className, onClick, size, color } = props;
  
    const handleKeyDownLeft = (e) => {
      if (e.key === 'ArrowLeft') {
        onClick();
      }
    };
  
    return (
      <div
        className={className}
        onClick={onClick}
        tabIndex={0}
        role="button"
      >
        <FontAwesomeIcon icon={faChevronLeft} color={color} size="6x" style={{ height: size }} />
      </div>
    );
  }

export {NextArrow, PrevArrow};
