import React from 'react'
import '../styles/components/Menu.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { chevronDown } from '@fortawesome/free-solid-svg-icons';
function Menu() {
  return (
        <div class="Content__dustMenu">
            <div class="Content__dustMenu--links">
                <div class="container">
                    <div class="Content__dustMenu--links-container">
                        <ul>
                            <li>
                                <div class="linkContainer">
                                    <a href="#">
                                        <span>Gamer</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 512 512"><path fill='white' d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                                    </a>
                                </div>
                                <div class="submenu">
                                    <div class="submenu__links">
                                        <ul>
                                            <li><a href="/categoria-producto/teclados-gamer/">Teclados Gamer</a></li>
                                            <li><a href="/categoria-producto/mouse-gamer/">Mouse Gamer</a></li>
                                            <li><a href="/categoria-producto/audifonos-gamer/">Audifonos Gamer</a></li>
                                            <li><a href="/categoria-producto/mousepad-gamer/">Mousepad</a></li>
                                            <li><a href="/categoria-producto/escritorios-gamer/">Escritorios Gamer</a></li>
                                            <li><a href="/categoria-producto/lentes-gaming/">Lentes Gaming</a></li>
                                            <li><a href="/categoria-producto/sillas-gamer/">Sillas Gamer</a></li>
                                        </ul>
                                    </div>
                                    <div class="submenu__links">
                                        <ul>
                                            <li><a href="/categoria-producto/kits-gamer/">Kits Gamer</a></li>
                                            <li><a href="/categoria-producto/parlantes-gamer/">Parlantes</a></li>
                                            <li><a href="/categoria-producto/soporte-de-audifonos/">Soporte De Audifonos</a></li>
                                            <li><a href="/categoria-producto/controles-y-volantes/">Controles y Volantes</a></li>
                                            <li><a href="/categoria-producto/iluminacion-rgb/">Iluminación RGB</a></li>
                                            <li><a href="/categoria-producto/otros-accesorios/">Otros Accesorios</a></li>
                                            <li><a href="/categoria-producto/membresias-online/">Membresias Online</a></li>
                                        </ul>
                                    </div>
                                    <div class="submenu__links">
                                        {/* <h4>Monitores Gamer</h4> */}
                                        <ul>
                                        <li><a href="/categoria-producto/monitores-gamer/">Monitores Gamer</a></li>
                                            {/* <li><a href="/categoria-producto/24-a-27-pulgadas/">24 A 27 Pulgadas</a></li>
                                            <li><a href="/categoria-producto/24-a-27-pulgadas/">29 o Superior</a></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="linkContainer">
                                    <a href="#">
                                        <span>Componentes</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 512 512"><path fill='white' d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                                    </a>
                                </div>
                                <div class="submenu">
                                    <div class="submenu__links">
                                        <ul>
                                            <li><a href="/categoria-producto/procesadores/">Procesadores</a></li>
                                            <li><a href="/categoria-producto/tarjetas-de-video/">Tarjetas De Video</a></li>
                                            <li><a href="/categoria-producto/placas-madres/">Placas Madre</a></li>
                                            <li><a href="/categoria-producto/fuentes-de-poder/">Fuentes De Poder</a></li>
                                            <li><a href="/categoria-producto/gabinetes/">Gabinetes</a></li>
                                            <li><a href="/categoria-producto/memorias-ram/">Memorias RAM</a></li>
                                        </ul>
                                    </div>
                                    <div class="submenu__links">
                                        <ul>
                                            <li><a href="/categoria-producto/pasta-termica/">Pasta Térmica</a></li>
                                            <li><a href="/categoria-producto/refrigeracion-liquida/">Refrigeración Líquida</a></li>
                                            <li><a href="/categoria-producto/fans-y-controladores/">Fans Y Controladores</a></li>
                                            <li><a href="/categoria-producto/cooler-para-cpu/">Cooler Para CPU</a></li>
                                            <li><a href="/categoria-producto/accesorios/">Accesorios Componentes</a></li>
                                        </ul>
                                    </div>
                                    <div class="submenu__links">
                                        <h4>Almacenamiento</h4>
                                        <ul>
                                            <li><a href="/categoria-producto/discos-m-2/">Discos M.2</a></li>
                                            <li><a href="/categoria-producto/ssd-y-discos-duros/">SSD Y Disco Duro</a></li>
                                            <li><a href="/categoria-producto/discos-y-ssd-externos/">Disco Y SSD Externo</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="linkContainer">
                                    <a href="#">
                                        <span>Streaming</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 512 512"><path fill='white' d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                                    </a>
                                </div>
                                <div class="submenu">
                                    <div class="submenu__links">
                                        <ul>
                                            <li><a href="/categoria-producto/webcams-streaming/">Webcams</a></li>
                                            <li><a href="/categoria-producto/microfonos/">Microfonos</a></li>
                                            <li><a href="/categoria-producto/iluminacion/">Iluminación</a></li>
                                            <li><a href="/categoria-producto/capturadoras/">Capturadoras</a></li>
                                            <li><a href="/categoria-producto/stream-decks/">Stream Decks</a></li>
                                            <li><a href="/categoria-producto/otros-accesorios-streaming/">Otros Accesorios</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="linkContainer">
                                    <a href="#">
                                        <span>Consolas</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 512 512"><path fill='white' d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                                    </a>
                                </div>
                                <div class="submenu">
                                    <div class="submenu__links">
                                        <h4>Nintendo</h4>
                                        <ul>
                                            <li><a href="/categoria-producto/juegos/">Juegos</a></li>
                                            <li><a href="/categoria-producto/controles-y-cargadores/">Controles</a></li>
                                            <li><a href="/categoria-producto/controles-y-cargadores/">Accesorios</a></li>
                                            <li><a href="/categoria-producto/fundas-y-estuches/">Fundas y Estuches</a></li>
                                        </ul>
                                    </div>
                                    <div class="submenu__links">
                                        <h4>Playstation 5</h4>
                                        <ul>
                                            <li><a href="/categoria-producto/controles-y-cargadores-ps5/">Controles</a></li>
                                            <li><a href="/categoria-producto/controles-y-cargadores-ps5/">Accesorios</a></li>
                                        </ul>
                                    </div>
                                    <div class="submenu__links">
                                        <h4>XBOX</h4>
                                        <ul>
                                            <li><a href="/categoria-producto/audifonos-xbox/">Audifonos XBOX</a></li>
                                            <li><a href="/categoria-producto/controles-y-cargadores-xbox/">Controles</a></li>
                                            <li><a href="/categoria-producto/controles-y-cargadores-xbox/">Accesorios</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="linkContainer">
                                    <a href="#">
                                        <span>Electrónica</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 512 512"><path fill='white' d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                                    </a>
                                </div>
                                <div class="submenu">
                                    <div class="submenu__links">
                                        <ul>
                                            <li><a href="/categoria-producto/impresoras/">Impresoras</a></li>
                                            <li><a href="/categoria-producto/routers-y-switches/">Routers Y Switches</a></li>
                                            <li><a href="/categoria-producto/antenas-tv/">Antenas TV</a></li>
                                            <li><a href="/categoria-producto/soportes-tv/">Soportes TV</a></li>
                                            <li><a href="/categoria-producto/respaldo-energia/">UPS Respaldo Energia</a></li>
                                            <li><a href="/categoria-producto/asistentes-virtuales/">Asistentes Virtuales</a></li>
                                            <li><a href="/categoria-producto/iluminacion-rgb-electronica/">Iluminación RGB</a></li>
                                        </ul>
                                    </div>
                                    <div class="submenu__links">
                                        <ul>
                                            <li><a href="/categoria-producto/enchufes-inteligentes/">Enchufes Inteligentes</a></li>
                                            <li><a href="/categoria-producto/smartband/">Smartband</a></li>
                                            <li><a href="/categoria-producto/atriles-y-tripodes/">Atriles Y Tripodes</a></li>
                                            <li><a href="/categoria-producto/tarjetas-de-memoria/">Tarjetas De Memoria</a></li>
                                            <li><a href="/categoria-producto/cables-y-adaptadores/">Cables Y Adaptadores</a></li>
                                            <li><a href="/categoria-producto/pendrives/">Pendrives</a></li>
                                            <li><a href="/categoria-producto/accesorios-tablets/">Accesorios Tablets</a></li>
                                        </ul>
                                    </div>
                                    <div class="submenu__links">
                                        <h4>Notebooks</h4>
                                        <ul>
                                            <li><a href="/categoria-producto/equipos/">Equipos</a></li>
                                            <li><a href="/categoria-producto/memorias-ram-notebooks/">Memorias RAM</a></li>
                                            <li><a href="/categoria-producto/bases-refrigeracion/">Bases Refrigeración</a></li>
                                            <li><a href="/categoria-producto/bolsos-y-maletines/">Bolsos Y Maletines</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="linkContainer">
                                    <a href="#">
                                        <span>Oficina y Hogar</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 512 512"><path fill='white' d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                                    </a>
                                </div>
                                <div class="submenu">
                                    <div class="submenu__links">
                                        <ul>
                                            <li><a href="/categoria-producto/teclados-perifericos/">Teclados</a></li>
                                            <li><a href="/categoria-producto/mouse-perifericos/">Mouse</a></li>
                                            <li><a href="/categoria-producto/webcam/">Webcam</a></li>
                                            <li><a href="/categoria-producto/audifonos-audio/">Audifonos</a></li>
                                            <li><a href="/categoria-producto/tabletas-digitalizadoras/">Tabletas Digitalizadoras</a></li>
                                            {/* <li><a href="#">Sillas</a></li> */}
                                            <li><a href="/categoria-producto/hubs-usb/">HUBS USB</a></li>
                                        </ul>
                                    </div>
                                    <div class="submenu__links">
                                        <ul>
                                            <li><a href="/categoria-producto/apoyo-lumbar/">Apoyos Lumbares</a></li>
                                            <li><a href="/categoria-producto/soporte-monitores/">Soporte Monitores</a></li>
                                            <li><a href="/categoria-producto/parlantes-audio/">Parlantes</a></li>
                                            <li><a href="/categoria-producto/combo-teclado-y-mouse/">Combos Teclado Y Mouse</a></li>
                                        </ul>
                                    </div>
                                    <div class="submenu__links">
                                        {/* <h4>Monitores Oficina</h4> */}
                                        <ul>
                                       
                                            <li><a href="/categoria-producto/monitores/">Monitores Oficina</a></li>
                                            {/* <li><a href="/categoria-producto/22-a-24-pulgadas/">22 A 24 Pulgadas</a></li>
                                            <li><a href="/categoria-producto/27-a-32-pulgadas-oficina/">27 A 32 Pulgadas</a></li>
                                            <li><a href="/categoria-producto/29-o-superior-oficina/">29 O Superior</a></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="linkContainer">
                                    <a href="#">
                                        <span>Computadores</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 512 512"><path fill='white' d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                                    </a>
                                </div>
                                <div class="submenu">
                                    <div class="submenu__links">
                                        <ul>
                                            <li><a href="/categoria-producto/setups/">Desktops</a></li>
                                            <li><a href="/categoria-producto/equipos/">Notebooks</a></li>
                                            <li><a href="/categoria-producto/aio/">All In One</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="linkContainer">
                                    <a href="#">
                                        <span>Pasatiempo</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 512 512"><path fill='white' d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                                    </a>
                                </div>
                                <div class="submenu">
                                    <div class="submenu__links">
                                        <ul>
                                            <li><a href="/categoria-producto/figuras-coleccionables/">Figuras Coleccionables</a></li>
                                            <li><a href="/categoria-producto/juegos-de-mesa/">Juegos de Mesa</a></li>
                                            <li><a href="/categoria-producto/accesorios-tcg/">Accesorios TCG</a></li>
                                        </ul>
                                    </div>
                                    <div class="submenu__links">
                                        <h4>TCG</h4>
                                        <ul>
                                            <li><a href="/categoria-producto/pokemon-tcg/">Pokemon</a></li>
                                            <li><a href="/categoria-producto/optcg/">One Piece</a></li>
                                            <li><a href="/categoria-producto/digimontcg/">Digimon</a></li>
                                            <li><a href="/categoria-producto/magic-tcg/">Magic: The Gathering</a></li>
                                            <li><a href="/categoria-producto/yugioh/">Yu Gi Oh</a></li>
                                            <li><a href="/categoria-producto/dbtcg/">Dragon Ball Super Card Game</a></li>
                                        </ul>
                                    </div>
                                    <div class="submenu__links">
                                        <h4>Mitos y Leyendas</h4>
                                        <ul>
                                            <li><a href="/categoria-producto/pe-myl/">Primera Era</a></li>
                                            <li><a href="/categoria-producto/pb-myl/">Primer Bloque</a></li>
                                            <li><a href="/categoria-producto/sb-myl/">Segundo Bloque</a></li>
                                            <li><a href="/categoria-producto/ne-myl/">Nueva Era</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="linkContainer">
                                    <a href="#">
                                        <span>LEGO</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 512 512"><path fill='white' d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                                    </a>
                                </div>
                                <div class="submenu">
                                    <div class="submenu__links">
                                        <ul>
                                            {/* <li><a href="/marca/lego/">Lego</a></li> */}
                                            <li><a href="/categoria-producto/architecture/">Architecture</a></li>
                                            <li><a href="/categoria-producto/botanical/">Botanical</a></li>
                                            <li><a href="/categoria-producto/city/">City</a></li>
                                            <li><a href="/categoria-producto/classic/">Classic</a></li>
                                            <li><a href="/categoria-producto/creator-3-en-1/">Creator 3 en 1</a></li>
                                            <li><a href="/categoria-producto/dc/">DC</a></li>
                                            <li><a href="/categoria-producto/disney/">Disney</a></li>
                                        </ul>
                                    </div>
                                    <div class="submenu__links">
                                        {/* <h4>TCG</h4> */}
                                        <ul>
                                        <li><a href="/categoria-producto/harry-potter/">Harry Potter</a></li>
                                        <li><a href="/categoria-producto/jurassic-world/">Jurassic World</a></li>
                                        <li><a href="/categoria-producto/animal-crossing/">Animal Crossing</a></li>
                                        <li><a href="/categoria-producto/avatar/">Avatar</a></li>
                                        <li><a href="/categoria-producto/icons/">Icons</a></li>
                                        <li><a href="/categoria-producto/ideas/">Ideas</a></li>
                                        <li><a href="/categoria-producto/linea-lego/">Linea LEGO</a></li>

                                        </ul>
                                    </div>
                                    <div class="submenu__links">
                                        {/* <h4>Mitos y Leyendas</h4> */}
                                        <ul>
                                        <li><a href="/categoria-producto/super-mario/">Super Mario</a></li>
                                        <li><a href="/categoria-producto/marvel/">Marvel</a></li>
                                        <li><a href="/categoria-producto/minecraft/">Minecraft</a></li>
                                        <li><a href="/categoria-producto/minifiguras/">Minifiguras</a></li>
                                        <li><a href="/categoria-producto/ninjago/">NINJAGO</a></li>
                                        <li><a href="/categoria-producto/speed-champions/">Speed Champions</a></li>
                                        <li><a href="/categoria-producto/star-wars/">Star Wars</a></li>
                                        <li><a href="/categoria-producto/technic/">Technic</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Menu