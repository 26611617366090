import React from 'react';
import '../styles/components/Loading.css';
import GifDusito from "../images/loading-dust2.gif";
const Loading = ({children, position, width}) => {
    return (
        <div className={`loading__dpb ${position}`}>
            {/* <div class="lds-heart"><div></div></div> */}
            <img style={{width: `${width}`,maxWidth: `${width}`}} src={GifDusito} alt="" />
            {children}
        </div>
    );
}
 
export default Loading;
