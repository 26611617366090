import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { NextArrow, PrevArrow } from './CustomArrows';
import ProductCardLiveCustom from './ProductCardLiveCustom';

const AutoLiquidableFloatCart = () => {
    const data = useStaticQuery(graphql`
    query {
        allProduct(
            filter: {
            tags: {elemMatch: {slug: {eq: "autoliquidable"}}}
            stock_status: {eq: "instock"}
            }) {
            nodes {
                id
                sku
                name
                slug
                price
                description
                stock_status
                stock_quantity
                categories {
                    id
                    name
                    slug
                }
                images {
                    src
                }
                product_card_image
                dimensions {
                    width,
                    height,
                    length
                }
                attributes {
                    name
                    slug
                    options
                }
                tags {
                    id
                    name
                    slug
                }
                date_created
                menu_order
            }
        }
    }
    `);

    function tieneTagVerde(producto) {
        return producto.tags.some(tag => tag.name.toUpperCase() === 'VERDE');
    }

    function filtrarStockEnStock(productos) {
        if (productos) {
            return productos.sort((a, b) => {
                const aEnStock = a.stock_status === 'instock';
                const bEnStock = b.stock_status === 'instock';
                const aTieneVerde = tieneTagVerde(a);
                const bTieneVerde = tieneTagVerde(b);

                if (aEnStock && !aTieneVerde && (!bEnStock || bTieneVerde)) {
                    return -1;
                }
                if (bEnStock && !bTieneVerde && (!aEnStock || aTieneVerde)) {
                    return 1;
                }
                if (aEnStock && aTieneVerde && !bEnStock) {
                    return -1;
                }
                if (bEnStock && bTieneVerde && !aEnStock) {
                    return 1;
                }

                if (!aEnStock && !bEnStock) {
                    if (!aTieneVerde && bTieneVerde) {
                        return -1;
                    }
                    if (aTieneVerde && !bTieneVerde) {
                        return 1;
                    }
                }

                return 0;
            });
        }
    }

    const products = filtrarStockEnStock(data.allProduct.nodes);
    const filterProductsImages = products?.filter((filterProduct) => {
        return filterProduct.images.length > 0;
    });
    const filterProducts = filterProductsImages.slice(0, 10);
    filterProducts.sort((a, b) => a.menu_order - b.menu_order);
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: <NextArrow color={"#ee9d05"} />,
        prevArrow: <PrevArrow color={"#ee9d05"} />,
    };

    return (
        <div style={{ maxWidth: "100%" }}>
            <div className="Content__lastProducts--title">
                <h4 style={{ paddingLeft: "10px", marginBottom: "10px", fontSize: "18px", paddingTop: "20px" }}>No te puedes perder…</h4>
            </div>
            <div className='productCustomCardContent'>
              {filterProducts?.map((product) => (
                <ProductCardLiveCustom title="Lo Quiero!" template="cart" theme="" key={product.wordpress_id} product={product}/>
              ))}
            </div>
               
        </div>
    );
}

export { AutoLiquidableFloatCart };
