import React from 'react';

const BrandFilter = ({ brands, selectedBrands, onBrandChange }) => {
  const handleCheckboxChange = (brand) => {
    onBrandChange(brand);
  };

  return (
    <div>
      {Array.isArray(brands) && brands.length > 0 ? ( 

          brands.map((brand) => (
            <div key={brand}>
              <input
                type="checkbox"
                id={brand}
                checked={!!selectedBrands[brand]}
                onChange={() => handleCheckboxChange(brand)}
              />
              <label htmlFor={brand}>{brand}</label>
            </div>
          ))

      ) : ( 

        <div>
          <label>No hay marcas disponibles.</label>
        </div>

      )}
      
    </div>
  );
};

export default BrandFilter;