import React from 'react';
import LayoutSearch from './LayoutSearch'; // Asegúrate de que esta ruta sea correcta

export const SearchResults = ({ results }) => {
  return (
    <div className='layoutSearch'>
        <div className='Content'>
            <div class="Content__productsCategories">
                <div style={{maxWidth: '1170px'}} class="container">
                    {/* <SliderCategories cat_id={id}/> */}
                    <LayoutSearch products={results} />
                </div>
            </div>
        </div>
    </div>
  );
};
