import React, { useContext, useState } from 'react';
import {MyContext} from '../context/AppContext';
import {GTMDataLayerPusher} from './Analytics/TagManager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faTrash, faCircleXmark, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { AutoLiquidableFloatCart } from './AutoLiquidableFloatCart';
// import { Link } from 'gatsby';
import CashIcon from '../images/cashIcon.webp';
import CardIcon from '../images/cardIcon.webp';

const FloatCart = ({cartModal}) => {
    const dataProductsCart = localStorage.getItem("products_cart");
    const { state, modalCart, removeFromCart, updateStockInCart } = useContext(MyContext);
	const sumTotal = () => {
        const reducer = (accumulator, currentItem) => 
          accumulator + (Number(currentItem.price) * Number(currentItem.quantity));
          
        const sum = state.cart.reduce(reducer, 0);
        return sum;
      }
    //   console.log(JSON.parse(dataProductsCart))
      const hasGreenTag = Array.isArray(JSON.parse(dataProductsCart)) && JSON.parse(dataProductsCart).some(product => 
        product.tags && product.tags.some(tag => tag.slug === 'verde')
    );
    // console.log(hasGreenTag)
    const sumTotalDiscount = () => {
        return sumTotal() * 0.07;
	}
    const closeModal = () => {
        modalCart(false);
        state.productSearched = [];
        // document.getElementById("search").value = "";
    }
    const handleRemove = product => {
        const gtmData = {
            event: "remove_cart",
            pagePath: "cart_modal",
            pageTitle: "",
            additionalData: {
                ecommerce: {
                    items: [{  // Lista de productos
                        item_name: product.name, // El nombre o ID es requerido.
                        item_id: product.sku,  // ID del producto.
                        price: product.price,  // Precio del producto.
                        item_brand: product.brand,  // Marca del producto.
                        item_category: product.category,  // Categoría general del producto.
                        quantity: product.quantity,  // Cantidad del producto.
                        item_SKU: product.sku,
                    }]
                    
                }
            }
        };
        GTMDataLayerPusher(gtmData);
        removeFromCart(product);
        // localStorage.setItem("products_cart", JSON.stringify(state.cart));
        // console.log(state.cart)
    };
    
    const handleUpdateQuantity = (product, quantity) => {
        const productCart = {
            wordpress_id: product.wordpress_id,
            quantity: product.slug === "x6-sobres-one-piece-tcg-eb-01-memorial-collection" ? 1 : quantity
        }


        // console.log(productCart)
        updateStockInCart(productCart)
    }
    
    return (
        <>
        <div className={cartModal ? "Content__fastCart cartActive" : "Content__fastCart"}>
            <div className="Content__fastCart--container">
                <div className="Content__fastCart--top">
                    <div className="Content__fastCart--top-closeCart" onClick={closeModal}>
                        <FontAwesomeIcon icon={faCircleXmark} color="#000"/>
                    </div>
                    <div className="Content__fastCart--top-title">
                        <h2>CARRO DE COMPRA</h2>
                    </div>
                </div>
                <div className="Content__fastCart--body-container">
                    {!!dataProductsCart && JSON.parse(dataProductsCart).map((product, index) => {
                        const hasProductGreenTag = product.tags && product.tags.some(tag => tag.slug === 'verde');
                        return (
                        <div className="Content__fastCart--body" key={index}>
                            <div className="Content__fastCart--body-amount">
                                <div className="fastCart__body--amount-plusOne">
                                    <FontAwesomeIcon style={{cursor: "pointer"}} onClick={() => {handleUpdateQuantity(product, product.quantity + 1)}} icon={faArrowUp} color="#000"/>
                                </div>
                                <div className="fastCart__body--amount-plusInput">
                                    <input type="text" name="productAmount" id="productAmount" value={product.quantity} />
                                </div>
                                <div className="fastCart__body--amount-minusOne">
                                    <FontAwesomeIcon style={{cursor: "pointer"}} onClick={() => {product.quantity > 1 ? handleUpdateQuantity(product, product.quantity - 1) : handleUpdateQuantity(product, product.quantity + 0)}} icon={faArrowDown} color="#000"/>
                                </div>
                            </div>
                            <div className="Content__fastCart--body-image">
                                <img src={product.image} alt="" />
                                {product.stock_quantity > 0 ? (
                                    <></>
                                ) : (
                                    <p className="floatOutOfStockAlert">Sin Stock</p>
                                )}
                            </div>
                            <div className="Content__fastCart--body-data">
                                <div className="fastCart__body--data-name">
                                    <h4>{product.name.substring(0, 38)}...</h4>
                                </div>
                                <div className="fastCart__body--data-price">
                                    <div className="body__data--price-cash">
                                    <img src={CashIcon} alt="" />
                                    <span>${new Intl.NumberFormat('de-DE').format(Number(product.price * product.quantity).toFixed(0))}</span>
                                    </div>
                                    <div className="body__data--price-card">
                                    <img src={CardIcon} alt="" />
                                    <span>${new Intl.NumberFormat('de-DE').format(Number(product.price / 0.93 * product.quantity).toFixed(0))}</span>
                                    </div>
                                </div>
                                {hasProductGreenTag && (
                                        <div>
                                            {/* Puedes reemplazar esto con tu ícono */}
                                            
                                            {/* <span className='icon_diferido'>
                                                <FontAwesomeIcon icon={faTriangleExclamation} color="#8f2323"/>
                                            </span> */}
                                            <p style={{paddingTop: "5px"}} className='product_diferido'>Producto despacho de hasta 3 días hábiles</p>
                                        </div>
                                    )}
                            </div>
                            <div className="Content__fastCart--body-delete" style={{cursor: 'pointer'}} onClick={() => handleRemove(product)}>
                                <FontAwesomeIcon icon={faTrash} color="#000000" style={{height: "20px", width: "20px"}}/>
                            </div>
                        </div>
                    )})}

                <AutoLiquidableFloatCart />
                </div>
                
                <div className="Content__fastCart--bottom">
                    <div className="fastCart__bottom--transferKhipu">
                        <div className="bottom__transferKhipu-text">
                        <p>Descuento por Transferencia</p>
                        </div>
                        <div className="bottom__transferKhipu-amount">
                        <p>-${new Intl.NumberFormat('de-DE').format(Number(sumTotal() / 0.93).toFixed(0) - sumTotal().toFixed(0))}</p>
                        </div>
                    </div>
                    <div className="fastCart__bottom--subtotal">
                        <div className="bottom__subtotal-text">
                        <p>Total Pago Tarjeta</p>
                        </div>
                        <div className="bottom__subtotal-amount">
                        <p>${new Intl.NumberFormat('de-DE').format(Number(sumTotal() / 0.93).toFixed(0))}</p>
                        </div>
                    </div>
                    <div className="fastCart__bottom--normalTotal">
                        <div className="bottom__subtotal-text">
                        <p>Total Pago Transferencia</p>
                        </div>
                        <div className="bottom__subtotal-amount">
                        <p>${new Intl.NumberFormat('de-DE').format(sumTotal().toFixed(0))}</p>
                        </div>
                    </div>
                    <div className="fastCart__bottom--buttons">
                        <div className="fastCart__bottom--buttons-goCartButton">
                            <a  href={`/carrito`} onClick={()=> {closeModal()}}>IR AL CARRO</a>
                        </div>
                        <div className="fastCart__bottom--buttons-payButton">
                            <a href={`/finalizar-compra`} onClick={()=> {closeModal()}}>PAGAR</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>               
        </>
    );
}

export default FloatCart;
