import React, {useState} from 'react';
import '../styles/components/Register.css';
import {MyContext} from '../context/AppContext';
import dusitoRegister from "../images/dusitoRegister1.webp";
import dusitoCongrats from "../images/DusitoCongrats.webp";
import dusitoStiker5 from "../images/DusitoSticker5.webp";
import dusitoClose from "../images/Icon-close.webp";
import dusitoPokemon from "../images/DUSITO_POKEMON.webp";
import {ClientWoocommerce} from '../utils/ClientWoocommerce';
import {GTMDataLayerPusher} from './Analytics/TagManager';
import Loading from './Loading';
const ModalRegister = () => {
    // const API_NEW_CUSTOMER = process.env.REACT_APP_CRM_BASE_URL + "/customer/";
    const API_NEW_CUSTOMER = ClientWoocommerce("/customers", "", 1);
    const avatars = [
        "https://crm.dust2.gg/images/1.webp",
        "https://crm.dust2.gg/images/2.webp",
        "https://crm.dust2.gg/images/3.webp",
        "https://crm.dust2.gg/images/4.webp",
        "https://crm.dust2.gg/images/5.webp",
        "https://crm.dust2.gg/images/6.webp",
        "https://crm.dust2.gg/images/7.webp",
        "https://crm.dust2.gg/images/8.webp",
        "https://crm.dust2.gg/images/9.webp",
        "https://crm.dust2.gg/images/10.webp",
        "https://crm.dust2.gg/images/11.webp",
        "https://crm.dust2.gg/images/12.webp",
        "https://crm.dust2.gg/images/13.webp",
        "https://crm.dust2.gg/images/14.webp",
        "https://crm.dust2.gg/images/15.webp",
        "https://crm.dust2.gg/images/16.webp",
        "https://crm.dust2.gg/images/17.webp",
        "https://crm.dust2.gg/images/18.webp",
        "https://crm.dust2.gg/images/19.webp"
    ];
    const { modalLogin, registerModal, modalRegister, addUserLogin } = React.useContext(MyContext);
    const [selectedAvatar, setSelectedAvatar] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [gtmData, setGtmData] = React.useState(null);
    const [form, setForm] = useState({
        userName: '',
        userLastName: '',
        email: '',
        password: '',
        avatar_url: ''
    });
    const handleInputChange = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        });
        // console.log(event.target.name);
    };
    const closeModalRegister = () => {
        modalRegister(false)
        modalLogin(false);
    }
    const clickRegisterNext = (bodyID, buttonID) => {
        document.querySelector(".Content__Register--body.bodyActive").classList.remove("bodyActive");
        document.getElementById(bodyID).classList.add('bodyActive');
        if (document.querySelectorAll(`.navActive`).length === 3) {
            document.querySelector(`.Content__Register--bottom`).remove();
        }else {
            document.querySelector(`[navType=${bodyID}]`).classList.add("navActive");
            if (bodyID === "chooseAvatarBody") {
                const gtmDataClickPromotion = {
                    event: "click_promotion",
                    pagePath: "/",
                    pageTitle: "Click en la promocion",
                    additionalData: {
                        ecommerce: {
                            coupon: "bienvenidoadust2"
                        }
                    }
                };
                GTMDataLayerPusher(gtmDataClickPromotion);
            }
        }
        document.querySelector(".Content__Register--body-button .buttonActive").classList.remove("buttonActive");
        document.getElementById(buttonID).classList.add('buttonActive');
    }
    const goToHome = () => {
        window.location.href = "/";
    }
    const handleAvatarChange = (e) => {
        setSelectedAvatar(e.target.value);
    };
    const cleanErrorMessage = (message) => {
        // Crear un elemento DOM temporal para manipular el contenido
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = message;
    
        // Quitar todos los enlaces
        const links = tempDiv.querySelectorAll('a');
        links.forEach(link => link.remove());
    
        return tempDiv.textContent || tempDiv.innerText || "";
    }
    const registerUser = async () => {
        // console.log(form)
        setLoading(true);
        try {
            const response = await fetch(API_NEW_CUSTOMER, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: form.email,
                    username: form.email,
                    first_name: form.userName,
                    last_name: form.userLastName,
                    password: form.password,
                    meta_data: [
                        {
                            key: "avatar_url",
                            value: form.avatar_url
                        }
                    ]
                })
            });

            const data = await response.json();

            if (data.id) {
                // console.log('Usuario creado con éxito', data);
                addUserLogin(data);
                console.log(data)
                clickRegisterNext('finalMessageBody', 'buttonNavigate')
                setLoading(false);
                const gtmDataLogin = {
                    event: "sign_up",
                    pagePath: "/cuenta",
                    pageTitle: "Cuenta",
                    additionalData: {
                        method: "web",
                        userId:  data.id
                    }
                };
                GTMDataLayerPusher(gtmDataLogin);
                // Aquí puedes manejar acciones post-registro, como mostrar un mensaje de éxito
            } else {
                setLoading(false);
                console.error('Error al registrar', data);
                const cleanedMessage = cleanErrorMessage(data.message || 'Error al registrar.');
                setErrorMsg(cleanedMessage);
                // Manejar el error (mostrar un mensaje al usuario, por ejemplo)
            }
        } catch (error) {
            console.error('Error de conexión', error);
            setErrorMsg('Error de conexión.');
            // Manejar el error (mostrar un mensaje al usuario, por ejemplo)
        }
    };

    return (
        <>
        <div class="Content__Register registerActive">
            {!!loading && (
                <Loading position="absolute" width={"185px"}> 
                    <h3>Creando Usuario...</h3>
                </Loading>
            )}
            <div class="container">
                <div class="Content__Register--container">
                    <div class="Content__Register--top">
                        <div class="Content__Register--top-icon desktopGato gatoActive">
                            <img src={dusitoRegister} alt="" />
                        </div>
                        <div class="Content__Register--top-icon mobileGato">
                            <img src={dusitoCongrats} alt="" />
                        </div>
                        <div class="Content__Register--top-icon registerGato1">
                            <img src={dusitoRegister} alt="" />
                        </div>
                        <div class="Content__Register--top-icon dusitoSticker">
                            <img src={dusitoStiker5} alt="" />
                        </div>
                        <div class="Content__Register--top-icon dusitoPokemon">
                            <img src={dusitoPokemon} alt="" />
                        </div>
                        <div onClick={closeModalRegister} class="Content__Register--top-close">
                            <img src={dusitoClose} alt="" />
                        </div>
                    </div>
                    <div id='welcomeBody' class="Content__Register--body bodyActive">
                        <div class="Content__Register--body-welcome">
                            <p>Bienvenido al mundo DUST2.</p>
                            <p>Sé parte de una de las comunidades Gamer más grandes del País. Sorteos, Concursos, Descuentos y mucho más están aquí. Regístrate y obtiene un 3% en tu primera compra.</p>
                        </div>
                        <div class="Content__Register--body-discount">
                            <h2>3%</h2>
                            <br />
                            <h4>DCTO.</h4>
                        </div>
                    </div>
                    <div id='chooseAvatarBody' class="Content__Register--body ">
                        <div class="Content__Register--body-title">
                            <h2>ELIGE TU AVATAR</h2>
                        </div>
                        <div class="Content__Register--body-avatars">
                            {avatars.map((avatar, index) => (
                                <div className="Register--body-avatars-avatar" key={index}>
                                    <input 
                                        type="radio" 
                                        id={`avatar${index+1}`} 
                                        name="avatar_url" 
                                        value={avatar} 
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor={`avatar${index+1}`}>
                                        <img src={avatar} alt={`Avatar ${index+1}`} />
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div id="registerBody" class="Content__Register--body ">
                        <div class="Content__Register--body-title">
                            <h2>INGRESA TUS DATOS</h2>
                        </div>
                        <div class="Content__Register--body-form">
                            <form action="">
                                <div class="Register__body--form-twoCols">
                                    <div class="Register__body--form-input">
                                        <label for="userName">Nombre</label>
                                        <input 
                                            type="text" 
                                            name="userName" 
                                            id="userName" 
                                            placeholder="Ingrese su nombre" 
                                            value={form.userName} 
                                            onChange={handleInputChange} 
                                        />
                                    </div>
                                    <div class="Register__body--form-input">
                                        <label for="userLastName">Apellido</label>
                                        <input 
                                            type="text" 
                                            name="userLastName" 
                                            id="userLastName" 
                                            placeholder="Ingrese su apellido" 
                                            value={form.userLastName} 
                                            onChange={handleInputChange} 
                                        />
                                    </div>
                                </div>
                                <div class="Register__body--form-input">
                                    <label for="email">Ingresa tu correo</label>
                                    <input 
                                        type="email" 
                                        name="email" 
                                        id="email" 
                                        placeholder="example@correo.cl" 
                                        value={form.email} 
                                        onChange={handleInputChange} 
                                    />
                                </div>
                                <div class="Register__body--form-input">
                                    <label for="password">Ingresa tu contraseña</label>
                                    <input 
                                        type="password" 
                                        name="password" 
                                        id="password" 
                                        placeholder="Password" 
                                        value={form.password} 
                                        onChange={handleInputChange} 
                                    />
                                </div>
                                <div class="Register__body--form-radioButton">
                                    <input type="radio" name="rememberMe2" id="rememberMe2" placeholder="rememberMe2" />
                                    <label for="rememberMe2">
                                        <span style={{paddingLeft: "5px"}}>Recuérdame</span>
                                    </label>
                                </div>
                            </form>
                            <div class="Register__body--form-error">
                                <p>{errorMsg}</p>
                            </div>
                        </div>
                    </div>
                    <div id="finalMessageBody" class="Content__Register--body">
                        <div class="Content__Register--body-title">
                            <h2>FELICITACIONES</h2>
                            <p>Ya estás registrado</p>
                        </div>
                    </div>
                    <div class="Content__Register--bottom-container">
                        <div class="Content__Register--body-button">
                            <button id='buttonRegister' onClick={()=>{clickRegisterNext('chooseAvatarBody', 'buttonNext')}} class="buttonActive">Registrarse</button>
                            <button id='buttonNext' onClick={()=>{clickRegisterNext('registerBody', 'buttonSubmit')}}>Continuar</button>
                            <button id='buttonSubmit' type="button" onClick={registerUser}>Registrarse</button>
                            {/* <button id='buttonSubmit' onClick={()=>{clickRegisterNext('finalMessageBody', 'buttonNavigate')}}>Registrarse</button> */}
                            <button id='buttonNavigate' onClick={closeModalRegister}>Ahora a navegar</button>
                        </div>
                        <div class="Content__Register--bottom">
                            <div navType="welcomeNav" class="Content__Register--bottom-nav navActive"></div>
                            <div navType="chooseAvatarBody" class="Content__Register--bottom-nav"></div>
                            <div navType="registerBody" class="Content__Register--bottom-nav"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {gtmData && <GTMDataLayerPusher {...gtmData} />}
        </>
    );
}

export default ModalRegister;
