import React, { useState, useEffect } from 'react';
import stringSimilarity from 'string-similarity';
import { navigate } from 'gatsby';

export const SearchInput = ({ query, onSearch }) => {
  const [searchTerm, setSearchTerm] = useState(query);

  function normalizeText(text) {
    // Eliminar caracteres especiales y convertir a minúsculas
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
  }
  
  // En tu componente de búsqueda
  const handleSearchChange = (e) => {
    const newQuery = normalizeText(e.target.value);
    setSearchTerm(newQuery); // Actualiza el estado local con la consulta normalizada
    onSearch(newQuery); // Realiza la búsqueda en tiempo real en la página de búsqueda
  };

  const handleSearchClick = () => {
    // Redirige a la página de búsqueda con el término de búsqueda como parámetro de consulta
    navigate(`/buscar/?q=${encodeURIComponent(searchTerm)}`);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      // Evita la recarga de la página al presionar Enter
      e.preventDefault();

      // Redirige a la página de búsqueda con el término de búsqueda como parámetro de consulta
      navigate(`/buscar/?q=${encodeURIComponent(searchTerm)}`);
    }
  };

  useEffect(() => {
    // Actualiza el estado local `searchTerm` cuando cambia la prop `query`
    setSearchTerm(query);
  }, [query]);

  return (
    <div className='Search__input-container'>
      <input
        className='Search__input'
        type="text"
        value={searchTerm}
        onChange={handleSearchChange}
        onKeyPress={handleKeyPress}
        placeholder="Buscar por nombre o SKU..."
      />
      <button className='Search__button' onClick={handleSearchClick}>Buscar</button>
    </div>
  );
};
